@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
    scroll-behavior: smooth;
} */

@layer base {
    @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');

    * {
        font-family: 'Raleway', sans-serif;
    }

    html {
        min-width: 320px;
        overflow-x: hidden;
    }

    html section {
        /* scroll-snap-align: start; */
    }

    h1, h2, h3, h4, h5, h6 {
        @apply text-white;
        /* @apply uppercase; */
        text-wrap: balance;
    }

    p, div {
        @apply text-white;
        text-wrap: balance;
        text-align: center;
    }

    .custom-gradient-border {
        background: rgb(38,104,75);
        background-size: 200%!important;
        background-image: linear-gradient(146deg, rgba(89, 50, 234, 1) 0%, rgba(29,29,27,1) 15%, rgba(89, 50, 234, 1) 25%, rgba(29,29,27,1) 35%, rgba(89, 50, 234, 1)50%, rgba(29,29,27,1) 70%, rgba(89, 50, 234, 1) 80%, rgba(29,29,27,1) 90%, rgba(89, 50, 234, 1) 100%);
        background-position: left;
        transition: background-position 1000ms!important;
    }

    .custom-gradient-border-2-colors {
        background: rgb(38,104,75);
        background-size: 220%!important;
        background-image: linear-gradient(146deg, rgba(89, 50, 234, 1) 0%, rgba(29,29,27,1) 15%, rgba(89, 50, 234, 1) 25%, rgba(29,29,27,1) 35%, rgba(89, 50, 234, 1)50%, rgba(29,29,27,1) 70%, rgba(89, 50, 234, 1) 80%, rgba(29,29,27,1) 90%, rgba(89, 50, 234, 1) 100%);
        background-position: left;
        transition: background-position 500ms!important;
    }

    .animating-border {
        animation: animatingBorder 15s infinite alternate-reverse;
    }

    .map-container {
        width: 100%;
        aspect-ratio: 16/6;
    }
    
    .map-wrapper {
        height: 100%;
        width: 100%;
    }

    .spacing-sections {
        padding-top: 10dvh;
        padding-bottom: 10dvh;
    }

    .pt-30 {
        padding-top: 30dvh!important;
    }

    .pb-10 {
        padding-bottom: 10dvh!important;
    }

    .bg-grey {
        background: grey;
    }

    .backdrop-blur-xl {
        backdrop-filter: blur(24px);
    }

    .btn-meet{
        background-color: #5932EA!important;
    }

    .btn-join{
        background-color: #5932EA!important;
    }

    .p-10 {
        padding: 3rem!important;
    }

    .animate-pulse {
        animation: pulse 5s cubic-bezier(0.4, 0, 0.6, 1) infinite!important;
    }
    
    .purple-gradient {
        height: 20%;
        width: 40%;
        background-image: radial-gradient(circle at top right, #5932EA 0%, transparent 50%);
    }
    .purple-gradient-left {
        height: 20%;
        width: 40%;
        background-image: radial-gradient(circle at center left, #5932EA 0%, transparent 45%);
    }

    .purple-gradient-bottom {
        height: 20%;
        width: 40%;
        background-image: radial-gradient(circle at bottom right, #5932EA 0%, transparent 50%);
    }

    .purple-gradient-middle {
        height: 20%;
        width: 40%;
        background-image: radial-gradient(circle at center, #5932EA 0%, transparent 50%);
        opacity: 30%;
    }

    .bg-green-btn {
        background-color: #5AF25F!important;
    }

    .bg-numbers {
        background-color: #5AF25F!important;
    }

    .bg-purple {
        background-color: #5932EA!important;
    }

    .bg-none {
        background-color: transparent!important;
    }

    .f-roboto {
        font-family: 'Roboto', sans-serif!important;
    }

    .timeline-seperator{
        background-color: transparent!important;
    }
    .invisible-seperator{
        border: none!important;
        background: none!important;
    }
    .timeline-seperator.top-seperator{
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(176,160,235,1) 100%);
    }
    .timeline-seperator.bottom-seperator{
        background: linear-gradient(180deg, rgba(176,160,235,1) 0%, rgba(255,255,255,1) 100%);
    }
    .timeline-dot{
        margin-top: 0px!important;
        margin-bottom: 0px!important;
        background-image: radial-gradient(circle at center, #5932EA 0%, #5932eac2 50%, transparent 90%);
        width: 30px!important;
        height: 30px!important;
        padding: initial!important;
        border: 0px solid #5932EA!important;
        background-color: transparent!important;
    }
    .timeline-card {
        /* display: flex; */
    }
    .timeline-card-content {
        background: linear-gradient(to right, #5932ea 0%, #272727 65%);
        backdrop-filter: blur(8px);
        border-radius: 8px;
        padding: 20px;
        text-align: center;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.2s ease-in-out;
        max-width: 400px;
        margin: 0 auto;
      }
      
      /* .timeline-card:hover {
        transform: scale(1.05);
      } */
      
      @media (max-width: 768px) {
        .timeline-card {
          padding: 15px;
        }
      }
      

    .swiper-wrapper .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: left;
        transition: background-position 500ms;
        
    }

    .swiper-wrapper .swiper-slide-active .slider-card-item {
        background: rgb(38,104,75);
        background-image: linear-gradient(146deg, rgb(43, 43, 40) 44%, rgba(38,104,75,1) 45%, rgba(38,104,75,1) 60%, rgb(174, 138, 45) 100%);
        background-position: right;
        transition: background-position 500ms;
    }

    .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
        background-size: 280vh;
    }      

    @keyframes animatingBorder {
        0% {
            background-position: left;
        }
        100% {
            background-position: right;
        }
    }

    :root{
        --primary-grey: #1D1D1B;
        --primary-yellow: '#DDAE3A';
        --primary-green: '#5AF25F';
        --primary-purple: '#5932EA';
    
        --transition-duration: 0.2s;
    }

    .overflow-inherit {
        overflow: inherit!important;
    }

    .image-list .animated-div{
        width: 100%;
        height: 100%;
    }
    .image-list .animated-div img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        display: block;
    }

    .link-element{
        padding: 5px 15px!important;
    }

    .bg-btn {
        background-color: #64748b!important;
    }

    .input-mailchimp {
        border-radius: 0.5rem;
        border: 1px solid #64748b;
        padding: 0.5rem 1rem;
        width: 75%!important;
        color: #64748b;
        background-color: transparent;
    }

    .input-mailchimp:focus {
        outline: none!important;
        border: 1px solid none!important;
    }

    .input-mailchimp::placeholder {
        color: #64748b;
    }


    /* tailwinds media queries */
    @media (min-width: 640px) /*sm*/ {}
    @media (min-width: 768px) /*md*/ {}
    @media (min-width: 1024px) /*lg*/ {}
    @media (min-width: 1280px) /*xl*/ {}
    @media (min-width: 1536px) /*2xl*/ {}

    @media (max-width: 768px) {
        #root section div h1:not(.overide-static-font), #root div h1:not(.overide-static-font) {
            @apply text-7xl;
        }
        #root section div h2:not(.overide-static-font), #root div h2:not(.overide-static-font) {
            @apply text-5xl;
        }
        #root section div h3:not(.overide-static-font), #root div h3:not(.overide-static-font) {
            @apply text-4xl;
        }
        #root section div h4:not(.overide-static-font), #root div h4:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h5:not(.overide-static-font), #root div h5:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h6:not(.overide-static-font), #root div h6:not(.overide-static-font) {
            @apply text-lg;
        }

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 230vh;
        }

        .md-pt-45 {
            padding-top: 45dvh!important;
        }

        .md-pb-10 {
            padding-bottom: 10dvh!important;
        }
        .customTimelineFlexClass {
            flex-direction: column!important;
        }
    }

    @media (max-width: 567px) {
        #root section div h1:not(.overide-static-font) {
            @apply text-4xl;
        }
        #root section div h2:not(.overide-static-font) {
            @apply text-3xl;
        }
        #root section div h3:not(.overide-static-font) {
            @apply text-2xl;
        }
        #root section div h4:not(.overide-static-font) {
            @apply text-xl;
        }
        #root section div h5:not(.overide-static-font) {
            @apply text-lg;
        }
        #root section div h6:not(.overide-static-font) {
            @apply text-base;
        }

        .swiper-wrapper .slider-card-item, .swiper-wrapper .swiper-slide-active .slider-card-item  {
            background-size: 250vh;
        }

        .md-pt-50 {
            padding-top: 50dvh!important;
        }

        .md-pb-10 {
            padding-bottom: 10dvh!important;
        }
    }

    @media (max-width: 425px) {
        .sm-pt-40 {
            padding-top: 40dvh!important;
        }

        .sm-pb-10 {
            padding-bottom: 10dvh!important;
        }
    }


    /* slideDown animation with keyframes */
    @keyframes slideDown {
        0% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
        100% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
    }
    .slideDown {
        animation: slideDown var(--transition-duration) ease-in-out;
    }

    /* slideUp animation with keyframes */
    @keyframes slideUp {
        0% {
            transform: translateY(0%) scale(1);
            opacity: 1;
        }
        100% {
            transform: translateY(-100%) scale(0.9);
            opacity: 0;
        }
    }
    .slideUp {
        animation: slideUp var(--transition-duration) ease-in-out;
    }



    /* Custom Browser compatabilities */
    .safari .team-card {
        width: 90%!important;
        height: 90%!important;
        margin: 0 auto!important;
    }
    .team-card .team-card-img-wrapper {
        width: 100%!important;
        height: 100%!important;
    }

    .CookieConsent {
        background: rgba(51, 51, 52, 0.75);
        opacity: 0.9;
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 1rem;
        padding: 1rem;
        border-radius: 1rem;
        z-index: 999;
        text-align: center;
        width: 350px;
        backdrop-filter: blur(24px);
    }

    .CookieConsent > div:nth-child(2) {
        transition: background-position 500ms!important;
        border-radius: 18px;
        padding: 2px;
        width: fit-content;
        margin: 10px auto;
        display: flex;
        justify-content: center;
    }
    
    .CookieConsent > div:nth-child(2) > button, .CookieConsent > div:nth-child(2) > div > button {
        width: auto;
        border-radius: 20px;
        padding: 8px 40px;
        color: black;
        font-weight: bolder;
        margin: 0 5px;
    }

    .white-underline {
        text-decoration: underline;
        text-decoration-color: white;
    }
}
